<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Barcazas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Embarcaciones de carga</li>
                  <li class="breadcrumb-item active">Barcazas</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-3 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Barcazas</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Documentos"
                    data-toggle="tab"
                    href="#Documentos"
                    v-if="form.id != null"
                    >Documentos</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <!-- Formulario -->
                      <div :class="form.id ? 'col-md-9' : 'col-md-12'">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="empresa">Empresa</label>
                              <v-select
                                :class="[
                                  $v.form.empresa_id.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                :disabled="opcion == 1 || opcion == 3"
                                v-model="empresa"
                                placeholder="Empresa"
                                label="razon_social"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.empresas"
                                @input="selectEmpresa()"
                              ></v-select>
                              <div
                                class="error"
                                v-if="!$v.form.empresa_id.required"
                              >
                                Seleccione una empresa
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="peso_vacio"
                                >capacidad toneladas</label
                              >
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="form.capacidad_toneladas"
                                :class="
                                  $v.form.capacidad_toneladas.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="placa">Nombre</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                id="placa"
                                maxlength="6"
                                v-model="form.nombre"
                                :class="
                                  $v.form.nombre.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaVehiculo()"
                              />
                              <div
                                class="error"
                                v-if="!$v.form.nombre.required"
                              >
                                Diligencie un nombre
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="eslora">Eslora</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                v-model="form.eslora"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="manga">Manga</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                v-model="form.manga"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="calado">Calado</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                v-model="form.calado"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="puerto_registro"
                                >Puerto de Registro</label
                              >
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="form.puerto_registro"
                                :class="
                                  $v.form.puerto_registro.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="empresa">Linea Negocio</label>
                              <v-select
                                :class="[
                                  $v.form.linea_negocio_id.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                :disabled="true"
                                v-model="linea"
                                placeholder="Linea"
                                label="nombre"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.lineas_negocio"
                                @input="selectLinea()"
                              ></v-select>
                              <div
                                class="error"
                                v-if="!$v.form.linea_negocio_id.required"
                              >
                                Seleccione una linea
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="bandera">Bandera</label>
                              <v-select
                              :class="[
                                  $v.form.bandera_id.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                v-model="bandera"
                                placeholder="Pais"
                                label="nombre"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.paises"
                                @input="selectBandera()"
                              ></v-select>
                              <div
                                class="error"
                                v-if="!$v.form.bandera_id.required"
                              >
                                Seleccione una bandera
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Fotografia -->
                      <div class="col-md-3" v-if="form.id">
                        <label for="imagen">Foto Vehículo</label>
                        <div
                          class="form-group"
                          v-if="form.link_fotografia == null"
                        >
                          <input
                            type="file"
                            class="form-control-file"
                            accept="image/x-png,image/gif,image/jpeg,image/png"
                            @change="obtenerImagen"
                          />
                        </div>
                        <figure class="form-group" v-if="miniLogo">
                          <div class="button-container">
                            <img
                              :src="miniLogo"
                              alt="Logo"
                              width="236"
                              height="125"
                              class="rounded mx-auto d-block"
                            />
                            <a
                              @click="destroyImage()"
                              id="BotonEliminar"
                              v-if="form.link_fotografia"
                              href="#"
                              :disabled="opcion == 1"
                              >Eliminar <i class="fas fa-window-close"></i
                            ></a>
                          </div>
                        </figure>
                        <div class="row">
                          <div class="col-md-9"></div>
                          <div class="col-md-3" v-if="archivoValido">
                            <button
                              type="button"
                              @click="saveImage()"
                              class="btn btn-success"
                              data-toggle="tooltip"
                              data-html="true"
                              title="Guardar Imagen"
                            >
                              <i class="fa fa-upload"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Documentos" v-if="form.id">
                  <BarcazaDocumento
                    v-if="form.id != null"
                    ref="BarcazaDocumento"
                  />
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    :disabled="opcion == 1"
                    @click="save()"
                    v-if="
                      $store.getters.can('admin.barcazas.create') ||
                        $store.getters.can('admin.barcazas.edit') ||
                        $store.getters.can(
                          'admin.barcazas.editarTransportadora'
                        )
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import BarcazaDocumento from "./BarcazaDocumento";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "BarcazaForm",
  components: {
    BarcazaDocumento,
    Loading,
    vSelect,
  },
  data() {
    return {
      opcion: 1,
      cargando: false,
      form: {
        empresa_id: null,
        nombre: null,
        capacidad_toneladas: null,
        puerto_registro: null,
        linea_negocio_id: 2,
        bandera_id: null,
      },
      uploadPercentage: 0,
      uploadPercentageFoto: 0,
      miniLogo: null,
      archivoValido: false,
      bandera: [],
      linea: { id: 2, nombre: "Hidrocarburos" },
      fileFotografia: null,
      accion: "",
      metodo: "",
      empresa: [],
      listasForms: {
        empresas: [],
        paises: [],
        lineas_negocio: [{ id: 2, nombre: "Hidrocarburos" }],
        estados: [],
        documentos: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      id: this.$route.params.id,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    
    };
  },
  validations() {
    return {
      form: {
        empresa_id: {
          required,
        },
        capacidad_toneladas: {
          required,
        },
        nombre: {
          required,
        },
        puerto_registro: {
          required,
        },
        linea_negocio_id: {
          required,
        },
        bandera_id: {
          required,
        },
      },
    };
  },
  methods: {
    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },
    async getLineasNegocio() {
      await axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    async getPaises() {
      await axios.get("/api/admin/paises/lista").then((response) => {
        this.listasForms.paises = response.data;
      });
    },
    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },
    selectLinea() {
      this.form.linea_negocio_id = null;
      if (this.linea) {
        this.form.linea_negocio_id = this.linea.id;
      }
    },
    selectBandera() {
      this.form.bandera_id = null;
      if (this.bandera) {
        this.form.bandera_id = this.bandera.id;
      }
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaVehiculo() {
      let params = {
        nombre: this.form.nombre,
      };

      if (this.form.nombre != null) {
        axios
          .get("/api/admin/barcazas/lista", {
            params,
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.form.nombre = null;
              this.$swal({
                icon: "error",
                title: "La barcaza digitada ya se encuentra creada...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
      }
    },

    // Funciones para carga de imagenes
    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/admin/barcazas/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.link_fotografia = response.data.link_fotografia;
          this.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/barcaza/foto/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo = null;
          this.form.link_fotografia = null;
        }
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/barcazas",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "El Vehículo se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    back(){
      return this.$router.replace("/Admin/Barcazas");
    },
    async init() {
      this.accion = this.$route.params.accion;
      this.opcion = this.$route.params.opcion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.miniLogo = this.form.link_fotografia
          ? this.uri_docs + this.form.link_fotografia
          : "";
        this.empresa = {
          id: this.form.empresa_id,
          razon_social: this.form.empresa.razon_social,
        };
        this.bandera = {
          id: this.form.bandera_id,
          nombre: this.form.pais.nombre,
        };
        this.metodo = "PUT";
      } 
    },
  },
  computed: {
    logo() {
      return this.miniLogo;
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getEmpresas();
    await this.init();
    await this.getLineasNegocio();
    await this.getPaises();
    /*     await this.getTiposCapacidad(); */

    await this.getEstados();
    this.cargando = false;
  },
  created() {},
};
</script>
